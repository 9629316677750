import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeliveryRoutingModule } from './delivery-routing.module';
import { ProjectsComponent } from './projects/projects.component';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxPaginationModule } from 'ngx-pagination';
import { DeliveryMetricsComponent } from '../delivery/delivery-metrics/delivery-metrics.component';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { NgxPermissionsModule } from 'ngx-permissions';



@NgModule({
  declarations: [
    ProjectsComponent,
    IndexComponent,
    DeliveryMetricsComponent,

  ],
  imports: [
    CommonModule,
    DeliveryRoutingModule,
    SharedModule,
    FormsModule, ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    NgxDatatableModule,
    MatMenuModule,
    MatButtonModule,
    MatOptionModule,
    NgxPaginationModule,
    MatExpansionModule,
    MaterialmodulesModule,
    NgxPermissionsModule
  ]
})
export class DeliveryModule { }
