<div *ngIf="!user.is_vendor">
  <app-filter-card [pagetype]="pagetype" [queryParam]="params"></app-filter-card>
</div>
<div class="px-0 py-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
    <div class="single-left-pane">
      <app-side-menu></app-side-menu>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="single-center-pane">
      <div class="text-center" *ngIf="portFolioList?.length == 0 && user?.is_vendor">
        No projects assigned.
      </div>
      <div class="d-flex mb-3" style="align-items: center; justify-content: space-between;">
        <div>
          <h6 *ngIf="portFolioCount" class="m-0">Total Projects - <span class="number-count">{{portFolioCount}}</span>
          </h6>
        </div>
        <div class="justify-content-end" *ngIf="OpportunityFieldList?.length > 0">
          <button mat-button (click)="add()" *ngIf="!user?.is_vendor"
              class="bg-primary text-white" mat-button
              mat-flat-button >
              <mat-icon aria-hidden="false" aria-label="material-icons-filled "
                  class="cursor-pointer fs-5">add
              </mat-icon>
              New Project
          </button>
      </div>
      </div>

      <app-loading *ngIf="!portFolioList"></app-loading>

      <div class="content-tree-holder mt-2" *ngIf="portFolioList">
        <app-tree-listing [items]="portFolioList" [tree]="program" [context]="context"></app-tree-listing>
      </div>
      
    </div>
  </div>
  <div class="col-sm-3">
    <div class="single-right-pane">
      <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
      <app-gnews [pagename]="'Project Central listing'"></app-gnews>
      <!-- <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                style="font-size: 13px">
                Curated from Web
            </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body p-2">
                <app-loading *ngIf="loading"></app-loading>
                <app-gnews [display_header]="'false'"></app-gnews>
            </div>
        </div>
    </div>    -->
    </div>
  </div>
</div>

<ng-template #delete>
  <div class="modal-dialog custom-width m-0">
    <div class="modal-content">
      <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>
      <div class="modal-body">
        <p class=" fs-5 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
      </div>
      <div class="modal-footer border-0">
        <div class="row">
          <div class="col-md-6">
            <button mat-button mat-dialog-close (click)="closeDialog()">{{staticText?.modal_popup?.no_btn}}</button>
          </div>
          <div class="col-md-6">
            <button mat-button mat-dialog-close (click)="deleteDialog()"
              class="bg-primary text-white">{{staticText?.modal_popup?.yes_btn}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>