import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../../../shared/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith,takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgramService } from 'src/app/shared/services/program.service';
import { DataToCsvService } from 'src/app/shared/services/data-to-csv.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DatePipe, Location } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { forEach, isArray } from 'lodash';
import _ from 'lodash';
import { FilterService } from 'src/app/shared/services/filter.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { MessageService } from 'src/app/shared/services/message.service';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers:[DatePipe],
  encapsulation:ViewEncapsulation.Emulated
  
})
export class ProjectsComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  OpportunityFieldList: any = [] = [];
  opportunityDataLIst: any = [] = [];
  projectList: any =[];
  projectListTotal: any;
  totalProjectCount: any;
  projectForm: FormGroup | any;
  filterForm: FormGroup | any;
  filteredOptions: any;
  limit: any;
  offset: any;
  datalist: any;
  totalCount: any;
  portList: any = [] = [];
  filterOptionPortfolio: Observable<any[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  page: number = 1;
  collection: any[] = [];
  portFolioId: any;
  subPortfilteredOptions: any[] = [];
  itemsPerPage: number = 10;
  subPortChildOptions: any[] = [];
  subPortId: any;
  searchText = '';
  sortBy = { name: 'name', value: 'ASC' };
  search = false;
  statusId: any;
  endVal: number = 10;
  order_by: string = "-last_updated_on"
  startVal: number = 0;
  showOpportunityBtn = false;
  offsetPaginate: any;
  deliveryProjectList: any = [] = [];
  Opportunitydropdownlist: any;
  opportunity: any[] = [];
  deleteID: any;
  projectMapForm = new FormGroup({});
  OpportunityList: any;
  opportunityFilterOptions: Observable<any[]> | any;
  projectFilterOptions: Observable<any[]> | any;
  fieldsdata: any;
  projectNumber: any;
  opportunityNumber: any;
  FormFieldOpportunity: any;
  FormFieldProject: any;
  SelectedOpportunity: any;
  selectedproject: any;
  option: any;
  opportunityLabel: any;
  projectLabel: any;
  projectId: any;
  scree_name = "projects";
  disableShowMore: boolean = false;
  statusList: any;

  searchData:any;
  isSearching:boolean=false;
  status: any;

  portFolioCount: number = 0;
  portFolioList: any
  public context: any;
  @ViewChild('delete') deleteModal:any;
  filterobject:any;
  pagetype = 'project'
  filterObj: any;
  filterField: any;
  params: any;
  portFolioListUnFiltered: any;
  archiveListData: any;
  user:any;
  loading:any = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private projectService: ProjectService, private toastr: ToastrService, private filterService: FilterService,
    private fb: FormBuilder, public dialog: MatDialog, private csvService:DataToCsvService,private _formValidator:FormValidatorService,
    private ps: ProgramService, private spinner: NgxSpinnerService, private router: Router, private route: ActivatedRoute,
    private messageService: MessageService,
    private location: Location, private toastrService: ToastrService,
    ) {
    this.projectMapForm = this.fb.group({
      opportunityname: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      opportunitynumber: [''],
      projectname: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]],
      projectnumber: ['']
    });
    this.route.queryParams.subscribe(async params => {
      this.params = params;
      this.location.replaceState(this.location.path().split('?')[0], '');
    });

    this.context = {
      componentParent: this,
    }
  }

  ngOnInit(): void {
     this.messageService.setGnews('Project Central listing')
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.fieldsdata = {
      opportunityname: true,
      opportunitynumber: false,
      projectname: false,
      projectnumber: false,
    }
    this.initProjectSearch();

   
   // this.getProjectList();  
    //  this.getAllPortfolioList();  
     this.getOpportunity();
    this.createForm();
    this.getStatus();
    this.filterService.setValue('');
    this.filterService.filterSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if(this.filterObj[element]?.array?.length > 0){
          awl = true;
          return;
        }
      });
      if(Object.keys(this.filterObj).length > 0){
        awl ? this.getFilteredList() : this.getAllPortfolioList();
      }else{
        this.getAllPortfolioList();
      }
    })
    this.filterService.filterFlag.subscribe((ele:any)=>{
      this.filterField = ele.field;
    })
  }

  initProjectSearch(){
    this.searchData= {
      projectName:"",
      projectId:"",
      programName:"",
      vendor:"",
      status:""
    }
  }

  createForm() {
    this.filterForm = this.fb.group({
      portfolio: [''],
      subPortfolio: [''],
      status: ['']
    });

    this.filterOptionPortfolio = this.filterForm.get('portfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((display_name: any) => display_name ? this.typeFilter(display_name) : this.portList.slice())
      );
  }

  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.portList.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }

  validateData(type: any) {
  }



  selectedPortfolioValues(id: any) {
    this.portFolioId = id;
    
    this.filterForm.patchValue({
      subPortfolio: ''
    })
    let selectedPortfolio = this.portList.find((e:any)=> e.id === id);
    this.subPortfilteredOptions = selectedPortfolio?.sub_portfolio;
    // this.filterOptionSubPortfolio = this.filterForm?.get('subPortfolio')!.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map((value: any) => typeof value === 'string' ? value : value?.display_name),
    //     map((display_name: any) => display_name ? this.subPortfolioFilter(display_name) : this.subPortfilteredOptions.slice())
    //   );
    if (this.portFolioId==="") {
      this.subPortfilteredOptions =[];
    }
    this.getProjectList();
  }


  clearFilter() {
    //this.portList = '',
      this.searchText = '';
    this.portFolioId = 0;
    this.subPortId = 0;
    this.statusId = 0;
    this.filterForm.patchValue({
      portfolio: '',
      subPortfolio: '',
      status: ''
    })
    this.getProjectList();
  }

  selectedSubportfolioValues(id: any) {
    this.subPortId = id;
    this.getProjectList();
  }
  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  getProjectList(isTotal:boolean=false) {
    this.spinner.show();
    let endVal:any = isTotal ? this.totalCount : this.endVal;
    this.projectService.projectList(this.startVal, endVal, this.subPortId, this.searchText, this.portFolioId,  this.statusId, this.order_by).subscribe((response: any) => {
      this.spinner.hide();
      if (response) {
        let list = response?.records;
        this.totalCount = response?.totalRecords;
        list.map((i: any) => {
          i.opportunity_number = i.opportunity?.opportunity_number;
          i.opportunity_name = i.opportunity?.opportunity_name;
          i.created_on = i.opportunity?.created_on;
          i.created_user_details = i.opportunity?.created_user_details;
          i.last_updated_on = i.opportunity?.last_updated_on;
        })
        list.forEach((element:any) => {
          this.projectList.push(element)

        });

        if(isTotal){
          this.projectListTotal = list;
          this.saveAsCSV();
        }else{
          this.collection.push(list);
        }
      }
      else {
        this.projectList = response?.records??[];
        this.projectListTotal = response?.records??[];
        this.totalCount = response?.totalRecords??0;
      }
    }, (error: any) => {
      this.spinner.hide();
      throw error
    })
  }
  programList: any[] = [];


  getOpportunity() {
    this.spinner.show();
    this.projectService.getOpportunityData('new_opportunity').subscribe((res: any) => {
      this.OpportunityFieldList = res.records;
      this.OpportunityFieldList.map((i: any) => {
        if (i.field_name == "opportunity_portfolio") {
          this.portList = i.field_values;
        }
      })
      if(this.OpportunityFieldList.findIndex((e: any) => e.field_name == 'program_name') < 0 ){
        let programdata =  {
              "id": 1,
              "field_name": "program_name",
              "display_name": "Program Name",
              "lookup_field": "program_name",
              "field_type": "text-box",
              "field_status": true,
              "model_reference": "''",
              "field_values":this.programList,
              "form_details": {
                  "id": 1,
                  "form_name": "program_name"
              }
            }
          
          this.OpportunityFieldList.push(programdata);
          
      }
      this.showOpportunityBtn = true;
      this.createForm();
      this.spinner.hide();
    }, error => {
      // this.toastr.error('Opportunity fields get failed');
      throw error;
    })
    this.ps.getProgramData().subscribe((res: any) => {
      this.programList = res;
     
    }, error => {
     // this.toastrService.error('Opportunity fields get failed', 'Error');
      throw error;
    })
  }


  searchName() {
    this.getProjectList();
  }
  getStatus() {
    this.ps.getProjectStatus().subscribe((res: any) => {
      this.statusList = res;
    }, error => {
      throw error;
    })
  }

  getDeliveryProjectList() {
    this.projectService.deliveryProjectList().subscribe((response: any) => {
      this.deliveryProjectList = response;

      this.projectFilterOptions = this.projectMapForm.get('projectname')!.valueChanges.pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.project_name),
        map((project_name: any) => project_name ? this.ProjectFilter(project_name) : this.deliveryProjectList.slice())
      );
    })
  }

  paginate(event: any) {
    this.limit = event.limit;
    this.offset = this.limit * event.offset;
    this.offsetPaginate = event.offset;
    const checkBox = document.getElementsByName('check');
    this.getProjectList()
  }

  sort(event: any) {
    if (this.totalCount > 1) {
      this.sortBy.name = event.column.prop;
      this.sortBy.value = event.newValue;
    }
  }

  deleteProject(id: any, content: any) {
    this.deleteID = id;
    this.dialog.open(content)
  }
  deleteDialog() {
    this.spinner.show();
    this.projectService.deleteProject(this.deleteID).subscribe((response: any) => {
      this.spinner.hide();
      this.dialog.closeAll();
      this.toastr.success("Project Deleted Successfully");
      this.getProjectList();
    }, error => {
      this.dialog.closeAll();
      this.spinner.hide();
      this.toastr.error("Error");
      throw error;
    });
  }

  mapdialog(type: any) {
    this.getopportuntydata();
    this.getDeliveryProjectList();
    this.getPortfolioList();
    this.dialog.open(type)
  }

  closeDialog() {
    this.dialog.closeAll();
  }



  viewProject(id: any) {
    this.router.navigate(['view/overview'])
  }
  editProject(id: any) {
    this.router.navigate(['view/overview'])
  }

  projectInfo(id: any) {
    this.projectId = id
    this.router.navigate([`view/`+btoa(id)+`/overview`]);
    this.projectService.bindEditId(this.projectId);
  }

  onChangeTable(page: any) {
    this.page = page;
    this.startVal = (page -1) * (this.itemsPerPage);
    this.endVal = this.startVal + (this.itemsPerPage);
    this.getProjectList();
  }

  statusChange(e: any) {
    this.statusId = e;
    this.searchData.status=e;


    this.callSearchApi();
  }

  downloadList() {
  }

  getPortfolioList() {
    delete this.portFolioList;
    if(!this.portFolioListUnFiltered){
      this.projectService.getPortfolioList().subscribe((res: any) => {
        this.portFolioList = res.length > 0 ? res : [];
        this.portFolioListUnFiltered = [...this.portFolioList];
      
        this.portFolioCount = res.total_opportunity_count;
        return res;
      })
    }else{
      this.portFolioList = this.portFolioListUnFiltered;
    }
  }

  getopportuntydata() {
    this.spinner.show();
    this.projectService.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
      this.OpportunityFieldList =res//.records;
      this.opportunityDataLIst = res//.records;
      this.spinner.hide();
      this.opportunityFilterOptions = this.projectMapForm.get('opportunityname')!.valueChanges.pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.opportunity_name),
        map((opportunity_name: any) => {return opportunity_name ? this.Filter(opportunity_name) : this.opportunityDataLIst.slice()})
      );
    })
  }
  private Filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.opportunityDataLIst.filter((i: any) =>{ return i?.opportunity_name?.toLowerCase().includes(filterValue)});
  }

  mapProject() {
    this.spinner.show();
    const data = {
      opportunity: this.SelectedOpportunity,
      project: this.selectedproject
    }

    this.projectService.projectmapping(data).subscribe((response: any) => {
      if (response.msg == "Opportunity is already mapped with this project" || response.msg == "This opportunity is already mapped with another project") {
        this.toastr.warning(response.msg);
        this.spinner.hide();
      }
      else {
        this.toastr.success(response.msg);
        this.dialog.closeAll();
        this.getProjectList();
        this.getopportuntydata();
        this.getDeliveryProjectList();
        this.spinner.hide();
        this.projectMapForm.reset();
      }
    });
  }
  get check() {
    return this.projectMapForm.controls;
  }

  onSelectionProjectName(event: any) {
    this.projectNumber = event;
    this.projectMapForm.patchValue({ projectNumber: this.projectNumber })
  }


  onSelectionOpportunityName(event: any) {
    this.opportunityNumber = event;
    this.projectMapForm.patchValue({ "opportunityNumber": this.opportunityNumber })
  }

  opportunityValue(data: any, label: any, name: any) {
    this.SelectedOpportunity = data;
    this.FormFieldOpportunity = label;

    this.opportunityLabel = label.opportunity_name;
    this.projectMapForm.patchValue({ "opportunityNumber": label })
  }
  projectyValue(data: any, label: any, name: any) {
    this.selectedproject = data;
    this.projectLabel = label;
    this.FormFieldProject = label;
  }

  private ProjectFilter(value: string): any {
    const filterValue = value.toLowerCase();
    this.option = value;
    return this.deliveryProjectList.filter((i: any) => i.project_name.toLowerCase().includes(filterValue));
  }

  cancel() {
    this.dialog.closeAll();
  }
  done() {
    this.dialog.closeAll();
  }

  displayOpportunityName(data: any) {
    return data && data.opportunity_name ? data.opportunity_name : '';
  }

  displayproject(data: any) {
    return data && data.project_name ? data.project_name : '';
  }

  callRefereshParentData(e: any) {
    this.getProjectList();
  }

  showAllProposals() {
    this.page = this.page +1;
    this.startVal = this.projectList?.length;
    this.endVal = this.itemsPerPage*this.page;
    this.getProjectList();
    if (this.totalCount < this.endVal) {
      this.disableShowMore = true;
    }
  }
  onSearch(event:any){
    console.log(event);
   if(event.type=='project_name'){
      this.searchData.projectName=event.query;

    }
    if(event.type=='project_id'){
      this.searchData.projectId=event.query;

    }
    if(event.type=='program_name'){
      this.searchData.programName=event.query;
 
    }
    if(event.type=='vendor'){
      this.searchData.vendor=event.query;
 
    }
    this.callSearchApi();











  }
  callSearchApi(){
    if(this.searchData.projectName||this.searchData.projectId||this.searchData.programName||this.searchData.vendor||this.searchData.status){
     this.isSearching = true;
      this.projectService.searchProjectList(this.searchData.projectName,
        this.searchData.projectId,
        this.searchData.programName,
        this.searchData.vendor,
        this.searchData.status).subscribe((response: any) => {
          if (response) {
            let list = response;
            this.totalCount = 0;
            list.map((i: any) => {
              i.opportunity_number = i.opportunity?.opportunity_number;
              i.opportunity_name = i.opportunity?.opportunity_name;
              i.created_on = i.opportunity?.created_on;
              i.created_user_details = i.opportunity?.created_user_details;
              i.last_updated_on = i.opportunity?.last_updated_on;
            })
            
              this.projectList =list;
              this.collection = list;
            
          }
          else {
            this.projectList = response?.records??[];
            this.projectListTotal = response?.records??[];
            this.totalCount = response?.totalRecords??0;
          }
        }, error => {
          throw error;
        });
    }else{
      this.clearFilter();

    }
  }
  clearFilterNew() {
    this.isSearching = false;

    this.initProjectSearch();
    this.getProjectList();


  }
  saveAsCSV() {
    if(this.projectListTotal && this.projectListTotal.length > 0){
      const items:any = [];
      this.projectListTotal.forEach((item:any) => {
        let csvData = {
          "Project ID": item.project_id,
          "Project Name": item.project_name,
          "Opportunity Number": item.opportunity_number,
          "Opportunity Name": item.opportunity_name,
          "Portfolio Name": item?.portfolio?.display_name,
          "Sub Portfolio Name": item?.sub_portfolio_details?.display_name,
          "Project Status":item.status?.action_complete_label
        }
        items.push(csvData); 
      });
      this.csvService.exportToCsv('Projects_List.CSV', items);
    }else{
      this.toastr.error('No Records to download');
    }
  }
  getAllPortfolioList(){
    this.projectService.getAllPortfolioList().then((res: any) => {
      this.portFolioList = res.records.length > 0 ? res.records : [];
     
      this.portFolioCount = res.total_projects_count;
      return res.records;
    })
  }

  async getFilteredList(){
    let filterArray:any = {portfolio:[],subportfolio:[],program:[],project:[],vendor:[],tags:[]};
    if(this.filterField == 'portfolio'){
      this.projectService.getAllPortfolioList().then((res: any) => {
        this.portFolioList = res.records.length > 0 ? res.records : [];
        this.portFolioListUnFiltered = [...this.portFolioList];
        this.portFolioCount = res.total_projects_count;
        if(this.filterObj.portfolio.array.length > 0){
          this.portFolioList = this.portFolioList.filter((el: any) => {
            return this.filterObj.portfolio.array.some((f: any) => {
              return f.id === el.id;
            });
          });
        }
      })
    }else{
      delete this.portFolioList;
      Object.keys(this.filterObj).forEach((key:any) => {
        this.filterObj[key].array.forEach((element:any) => {
          switch (key) {
            case "portfolio":
              filterArray.portfolio.push(element.id);
              break;
            case "subportfolio":
              filterArray.subportfolio.push(element.id);
              break;
            case "program":
              filterArray.program.push(element.id);
              break;
            case "project":
              filterArray.project.push(element.id);
              break;
            case "vendor":
              filterArray.vendor.push(element.id);
              break;
            case "tag":
              filterArray.tags.push(element.id);
              break;
            
            default:
              break;
          }
        });
      });
      await this.projectService.getFilteredList(filterArray,'').then((res: any) => {
        this.portFolioList = [];
        res.forEach((es: any) => {
          this.portFolioList.push(es.portfolio);
        });
        this.portFolioList = this.formatData(this.portFolioList);
      })
    }
    
    // else if(this.filterField == 'subportfolio'){
    //   delete this.portFolioList;
    //   let bodyParam: any = {
    //     subportfolio: []
    //   }
    //   this.filterObj.subportfolio.array.forEach((element: any) => {
    //     bodyParam.subportfolio.push(element.id)
    //   });
      
    //   await this.projectService.getFilteredList(bodyParam,'').then((res: any) => {
    //     this.portFolioList = res.portfolio;
        
    //     this.portFolioList = this.formatData(res.portfolio);
    //   })
    // }else if(this.filterField == 'program'){
    //   delete this.portFolioList;

    //   let bodyParam: any = {
    //     program: []
    //   }
    //   this.filterObj.program.array.forEach((element: any) => {
    //     bodyParam.program.push(element.id)
    //   });

    //   await this.projectService.getFilteredList( bodyParam,'').then((res: any) => {
    //     this.portFolioList = [];

    //     this.portFolioList = this.formatData(res.portfolio);
    //   })
    // }else if(this.filterField == 'project'){
    //   delete this.portFolioList;
      
    //   let bodyParam: any = {
    //     project: []
    //   }
    //   this.filterObj.project.array.forEach((element: any) => {
    //     bodyParam.project.push(element.id)
    //   });

    //   await this.projectService.getFilteredList(bodyParam,'').then((res: any) => {
    //     this.portFolioList = [];

    //     this.portFolioList = this.formatData(res.portfolio);
    //   })
    // }else if(this.filterField == 'vendor'){
    //   delete this.portFolioList;
      
    //   let bodyParam: any = {
    //     vendor: []
    //   }
    //   this.filterObj.vendor.array.forEach((element: any) => {
    //     bodyParam.vendor.push(element.id)
    //   });


    //   await this.projectService.getFilteredList(bodyParam,'').then((res: any) => {
    //     this.portFolioList = [];
    //     this.portFolioList = this.formatData(res.portfolio);
    //   })
    // }else if(this.filterField == 'tag'){
    //   delete this.portFolioList;

    //   let bodyParam: any = {
    //     tag: []
    //   }
    //   this.filterObj.tag.array.forEach((element: any) => {
    //     bodyParam.tag.push(element.id)
    //   });

    //   await this.projectService.getFilteredList(bodyParam,'').then((res: any) => {
    //     this.portFolioList = [];
        
    //     this.portFolioList = this.formatData(res.portfolio);
    //   })
    // }else{
    //   this.portFolioList = [...this.portFolioListUnFiltered];

    //   if(this.filterObj.portfolio.length > 0){
    //     this.filterObj.portfolio.forEach((element: any) => {
    //       this.portFolioList = this.portFolioList.filter((e: any) => e.id == element.id);
    //     });
    //   }
    // }
  }

  formatData(port: any){
    port.forEach((port: any) => {
        port.children = port.subportfolio;
        port.children.forEach((sub: any) => {
          sub.children = sub.program;
          if(sub.children){
            sub.children.forEach((program: any) => {
              program.children = isArray(program.project) ? program.project : [program.project] ;
              program.level = 'program';
              if(program.children){
                program.children.forEach((project: any) => {
                  project.level = 'last';
                  project.parent = {
                    id: program.id,
                    level: 'program',
                    name: program.name,
                    manager: program.manager,
                    parent: {
                      id: sub.id,
                      level: 'sub_portfolio',
                      name: sub.name,
                      parent: {
                        id: port.id,
                        level: 'portfolio',
                        name: port.name,
                      }
                    }
                  };
                  
                })
              }
              program.parent = {
                id: sub.id,
                level: 'sub_portfolio',
                parent: {
                  id: port.id,
                  level: 'portfolio'
                }
              }
            })
            sub.parent = {
              id: port.id,
              level: 'portfolio'
            }
          }
        });
    });

    return port;
  }


  collapse(item: any){
    if(item.level == 'portfolio'){
      this.portFolioList.forEach((element: any) => {
        if(element.id != item.id) element.expanded = false;
      });
    }else if(item.level == 'sub_portfolio'){
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.id);

      if(this.portFolioList[portfolioIndex].children.length > 0){
        this.portFolioList[portfolioIndex].children.forEach((element: any) => {
          if(element.id != item.id) element.expanded = false;
        });
      }
    }else if(item.level == 'program'){
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);

      if(this.portFolioList[portfolioIndex].children[subportfolioIndex].children.length > 0){
        this.portFolioList[portfolioIndex].children[subportfolioIndex].children.forEach((element: any) => {
          if(element.id != item.id) element.expanded = false;
        });
      }
    }
  }
  async getLevel(item: any = null){
    if(item.children){
      return
    }
    let response: any;
    if(item.level == 'portfolio'){
      response = await this.projectService.getSubPortfolioList(item).then((res: any) => {
        if(res.records.length > 0){
          res.records.forEach((element: any) => {
            element.parent = item
          });
        }
       
       this.portFolioList[this.portFolioList.indexOf(item)].children = res.records;
        return res;
      })
    }else if(item.level == 'sub_portfolio'){
      response = await this.projectService.getPortfolioPrograms(item).then((res: any) => {
        let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.id);
        let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.id);
        if(res.records.length > 0){
          res.records.forEach((element: any) => {
            element.level = 'program';
            element.parent = {
              id: this.portFolioList[portfolioIndex].children[subportfolioIndex].id,
              display_name:this.portFolioList[portfolioIndex].children[subportfolioIndex].display_name,

              level: 'sub_portfolio',
              parent: {
                id: this.portFolioList[portfolioIndex].id,
                display_name:this.portFolioList[portfolioIndex].display_name,
                level: 'portfolio'
              }
            }
          });
        }

          this.portFolioList[portfolioIndex].children[subportfolioIndex].children = res.records;

       
        return res;
      })
    }else if(item.level == 'program'){
      response = await this.projectService.getOpportunityList(item).then((res: any) => {
        let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.id);
        let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
        let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.id);

        if(res.records.length > 0){
          res.records.forEach((element: any) => {
            element.level = 'last';
            element.parent = this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex];

          });
        }
      
        this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children = res.records;

        return res;
      })
    }
    return response;
  }

  navigateTo(item:any){
   let newitem = {
    project_id:item.id,
    program_name: item.parent.name,
    program_id: item.parent.id,
    subportfolio_name: item.parent.parent.name || item.parent.parent.display_name,
    subportfolio_id: item.parent.parent.id,
    portfolio_name: item.parent.parent.parent.name || item.parent.parent.parent.display_name,
    portfolio_id: item.parent.parent.parent.id,
    manager: item.parent.manager
   }
   localStorage.setItem("program",JSON.stringify(newitem))
    this.router.navigate([`view/`+btoa(item.id)+`/overview`]);

  }

  deleteItem(item: any) {
    this.spinner.show();
    this.projectService.deleteProjectById(item.id).then((res: any) => {
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.parent.id);
      let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
      let projectIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.findIndex((e: any) => e.id == item.id);
      
      this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.splice(projectIndex, 1);
      this.spinner.hide();
      this.toastrService.success('Deleted Successfully');
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error('Error');
      throw error;
    })
  }

  archiveItem(item: any) {
    this.spinner.show();
    this.projectService.archiveProjecById(item.id).then((res: any) => {
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.parent.id);
      let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
      let projectIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.findIndex((e: any) => e.id == item.id);
      
      this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children.splice(projectIndex, 1);
      this.spinner.hide();
      this.toastrService.success('Archive Successfully');
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error('Error');
      throw error;
    })
  }

  archiveList(item: any){
    this.spinner.show();
    this.archiveListData = [];
    this.projectService.getArchiveList(item.parent.parent.id, item.parent.id, item.id).then((res: any) => {
      let portfolioIndex = this.portFolioList.findIndex((e: any) => e.id == item.parent.parent.id);
      let subportfolioIndex = this.portFolioList[portfolioIndex].children.findIndex((e: any) => e.id == item.parent.id);
      let programIndex = this.portFolioList[portfolioIndex].children[subportfolioIndex].children.findIndex((e: any) => e.id == item.id);

      this.spinner.hide();
      this.archiveListData = res;
      this.archiveListData.forEach((element: any) => {
        element.selected = false;
      });
      
      if(this.archiveListData.length > 0){
        const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
        dialog.componentInstance.type = 'ArchiveList';
        dialog.componentInstance.typeName = 'Project';
        dialog.componentInstance.archiveList = this.archiveListData;
  
        dialog.afterClosed().subscribe(val => {
          let payload: any = {
            project: []
          };
          val.forEach((element: any) => {
            if(element.selected){
              payload.project.push(element.id);
            }
          });
          this.spinner.show();
          if(payload.project.length > 0){
            this.projectService.unarchiveProj(payload).then((res: any) => {
              this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].expanded = false;
              delete this.portFolioList[portfolioIndex].children[subportfolioIndex].children[programIndex].children;
              this.toastrService.success('Unarchived successfully');
              this.spinner.hide();
            })
          }
        })
      }else{
        this.toastrService.warning('No archive list found under this program !');
      }
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error('Error');
      throw error;
    })
  }

  // deleteItem(item:any){
  // this.deleteProject(item.id,this.deleteModal)
  // }

  onFilterOptionsChanged(event:any){
    this.filterobject = event;
  }


  add() {
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true, width: '700px' });
    dialog.componentInstance.type = 'AddProject';
    dialog.componentInstance.OpportunityFieldList = this.OpportunityFieldList;
  }
  
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  

}





