<div class="d-flex bg w-100 h-100 m-0 px-5 pt-3 pb-3 overview scroll">
    <div class="section-2 col-md-9 p-0 px-0">
        <div class="container-fluid p-0">
            <div class="row p-0 m-0 pt-4">
                <div class=" pl-4">
                    <div class="sub-title">
                        <app-bread-crumb></app-bread-crumb>
                        <!-- <a class="breadcrums">Opportunity / FlyDeltaWebsite development / project setup </a> -->
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="cursor-pointer">
                            <p class="screen-title" (click)="scorecard()">
                                <i class="far fa-angle-left pr-2 "></i> {{staticText.delivery.delivery_metrics.title}} -
                                {{merticname | titlecase}}
                            </p>
                        </div>

                        <div>
                            <button mat-button mat-flat-button class="bg-primary text-white w-100 border"
                                (click)="onFormSubmit()"
                                *ngxPermissionsOnly="['delivery.change_projectmetricindicators']">
                                {{staticText?.buttons?.save_btn}}
                            </button>
                        </div>
                    </div>
                    <hr>
                    <div class="row" *ngIf="selectedMetricList.length >= 0">
                        <form [formGroup]="metricsForm" class="p-0">
                            <div class="col-md-12 p-0" formArrayName="items">
                                <div class="d-flex justify-content-between align-items-center pb-4 mx-3"
                                    *ngFor="let item of selectedMetricList; let k=index">
                                    <div>
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header class="mat-view mt-2">
                                                <mat-panel-title
                                                    class="text-uppercase font-style expansion-title expansion-title">
                                                    {{item.display_name}}
                                                </mat-panel-title>
                                                <div class="row align-items-center w-100">
                                                    <div class="col-md-11 p-0">
                                                        <br>
                                                        <small class="description">{{item.description}}</small>
                                                    </div>

                                                    <div class="col-md-1"
                                                        (click)="item.is_important = !item.is_important">
                                                        <i class="fal fa-star important" formcontrolname="is_important"
                                                            [ngClass]="{'important': item.is_important == true ,  'not_important': item.is_important == false }"></i>
                                                    </div>
                                                </div>
                                            </mat-expansion-panel-header>
                                            <div class="row expansion-form">
                                                <div>
                                                    <p class="pl-4 pt-2">
                                                        {{staticText.delivery.delivery_metrics.data_source}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <div [formGroupName]="k">
                                                        <div class="form-row  px-4 pb-2">
                                                            <div class="col-md-2 p-0 pl-2 ">
                                                                <input type="text"
                                                                    class="form-control form-field rounded-right"
                                                                    value="Jira"
                                                                    placeholder="{{staticText.delivery.delivery_metrics.jira}}">
                                                            </div>
                                                            <div class="col-md-10 p-0 pl-2">
                                                                <input type="text"
                                                                    class="form-control form-field rounded-left"
                                                                    formControlName="sourceLink"
                                                                    placeholder="https://example.com/"
                                                                    placeholder="{{staticText.delivery.delivery_metrics.enter_src_link}}">
                                                            </div>
                                                        </div>

                                                        <div class="form-row px-3 pb-2">
                                                            <div class="col-md-6">
                                                                <label
                                                                    class="mr-3">{{staticText.balance_scorecard.dashboard.unit}}</label>
                                                                <select class="form-control form-field"
                                                                    formControlName="unit_id">
                                                                    <option *ngFor="let unitData of unitlist"
                                                                        value="{{unitData.id}}">
                                                                        {{unitData.display_name}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>{{staticText.delivery.delivery_metrics.frequency}}</label>
                                                                <select class="form-control form-field"
                                                                    formControlName="trend_analysis_id">
                                                                    <option value="0" class="cursor-pointer">
                                                                        {{staticText.delivery.delivery_metrics.select_option}}
                                                                    </option>
                                                                    <option *ngFor="let frequencyData of frequencyData"
                                                                        value="{{frequencyData.id}}">
                                                                        {{frequencyData.display_name}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-row px-3 pb-2 mb-2">
                                                            <div class="col-md-6">
                                                                <label>{{staticText.delivery.delivery_metrics.target}}</label>
                                                                <input type="number" class="form-control form-field"
                                                                    formControlName="recommended" placeholder="100">
                                                            </div>
                                                            <div class="col-md-2">
                                                                <label
                                                                    class="red-value">{{staticText.color_names.red}}</label>
                                                                <input type="text" class="form-control form-field-1"
                                                                    formControlName="red_range_value"
                                                                    placeholder="0-10">
                                                            </div>
                                                            <div class="col-md-2">
                                                                <label
                                                                    class="amber-value">{{staticText.color_names.amber}}</label>
                                                                <input type="text" class="form-control form-field-2"
                                                                    formControlName="amber_range_value"
                                                                    placeholder="10-20">
                                                            </div>
                                                            <div class="col-md-2">
                                                                <label
                                                                    class="green-value">{{staticText.color_names.green}}</label>
                                                                <input type="text" class="form-control form-field-3"
                                                                    formControlName="green_range_value"
                                                                    placeholder="20-30">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </div>
                                    <div class="pl-2">
                                        <mat-icon class="cursor-pointer material-icons muted fs-5 ml-2 mt-2"
                                            (click)="removeMetrics(item)"
                                            *ngxPermissionsOnly="['delivery.delete_projectmetricindicators']">
                                            cancel
                                        </mat-icon>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" *ngIf="items?.length == 0 || selectedMetricList?.length == 0">
                        <div class="col-md-5 mx-auto">
                            <img src="./assets/images/no-record.png" class="noFound" height="100%"
                                width="100%">

                            <!--  <img src="../../../../../assets/images/background.png" class="noFound" height="100%"
                                width="100%"> -->
                        </div>
                        <div class="col-md-8 mx-auto">
                            <p class="text-center text-muted"> {{staticText.delivery.delivery_metrics.para1}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-3 p-0 ml-3 section-3 overflow-auto">
        <div>
            <div class="d-flex justify-content-center align-items-center header">
                <div class="col-md-10 p-0 pb-0 pt-3">
                    <p class="help m-0">{{staticText.balance_scorecard.dashboard.metrics}}</p>
                </div>
                <div class="pt-3 cursor-pointer" (click)="closePanel($event)">
                    <i class="far fa-times-circle close"></i>
                </div>

            </div>
            <hr class="m-0">
            <div class="pl-2 pr-3 py-3 search-bar">
                <mat-form-field appearance="outline" class="w-100 m-1 search" id="searchInput">
                    <!--  (keyup)="onChange()" -->
                    <input type="text" matInput class="w-100 p-0" (input)="onChange()" [(ngModel)]="searchText"
                        placeholder="{{staticText.delivery.delivery_metrics.search_metrics}}...">
                    <mat-icon matPrefix class="muted iconPos  cursor-pointer">search
                    </mat-icon>
                </mat-form-field>
            </div>
            <div class="px-2 metriclist">
                <ul class="list-group list-group-flush" *ngFor="let data of filteredData">
                    <li class="list-group-item py-2 px-1 d-flex justify-content-between align-items-center">
                        <div>
                            <p class="list-data" data-toggle="tooltip" data-placement="top"
                                title="{{data?.display_name}}">
                                {{data?.display_name}}</p>
                        </div>
                        <div>
                            <p class="addmetrics pl-4" (click)="addMetric(data)"
                                *ngxPermissionsOnly="['delivery.add_projectmetricindicators']"> <i
                                    class="far fa-plus"></i>
                            </p>
                        </div>
                    </li>
                    <hr class="m-0">
                </ul>

            </div>

            <div class="px-4 font-weight-bold" *ngIf="filteredData == ''">
                <p class="text-muted"> {{staticText.common.no_records_found}}...</p>

            </div>

        </div>
    </div>
</div>