import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from '../delivery/index/index.component';
import { DeliveryMetricsComponent } from '../delivery/delivery-metrics/delivery-metrics.component';

import { ProjectsComponent } from './projects/projects.component';
import { ProjectService } from 'src/app/shared/services/project.service';



const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    children: [
      {
        path: 'projects',
        data:{ breadcrumb: "Project Central"},
        component: ProjectsComponent,
      },
      {
        path: ':id/metrics/:metricId/:metricname',
        data: { breadcrumb: (data: any) => `${data?.project?.project_id}` },
            resolve: { project: ProjectService },
        component: DeliveryMetricsComponent
      },
      {
        path: 'metrics',//data:{ breadcrumb: "Project Setup"},
        component: DeliveryMetricsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryRoutingModule { }
