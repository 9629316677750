import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/shared/services/project.service';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { I } from '@angular/cdk/keycodes';
import { ToastrService } from 'ngx-toastr';
import { LodashService } from 'src/app/shared/services/lodash.service';
var _ = require('lodash');
var BreakException = {};
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NgxPermissionsService } from 'ngx-permissions';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-delivery-metrics',
  templateUrl: './delivery-metrics.component.html',
  styleUrls: ['./delivery-metrics.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class DeliveryMetricsComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  projectId = 0;
  metricsdata: any;
  unitlist: any[] = [];
  frequencyData: any[] = [];
  metricformvalues: any;
  metricsForm: any;
  metricDeliveryForm: FormGroup | any;
  noRecords: any;
  Employee: any = [];
  sectionList: any[] = [];
  formPatchdata: any[] = [];
  selectedMetricList: any[] = [];
  formarrayvalues: any;
  items: any;
  metric_category_id: any;
  rightpanel: any;
  searchText: any;
  filteredData: any = [];
  selectedmetrics: any;
  norecordFound: any;
  merticname: any;
  btoa:any=btoa

  constructor(private router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private permissionsService: NgxPermissionsService) {
  }

  ngOnInit(): void {
    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem('permission') || '{}').permissions);
    this.getprojectId();
    this.getSelectedMetricData();
    this.getUnit();
    this.frequencyList();
    this.noRecords = "show";
    this.rightpanel = "show";
    this.createForm();
  }

  createForm() {
    this.metricsForm = new FormGroup({
      items: new FormArray([])
    });
  }

  BuildForm(data: any): FormGroup {
    return this.fb.group({
      description: [data?.description || ''],
      name: [data?.name || ''],
      Jira: [''],
      sourceLink: [data?.sourceLink],
      recommended: Number(data?.recommended || ''),
      trend_analysis_name: [data.trend_analysis_id],
      unit_name: [data.display_name],
      red_range_value: [data?.red_range_value || ''],
      amber_range_value: [data?.amber_range_value || ''],
      green_range_value: [data?.green_range_value || ''],
      amber_range_id: [''],
      green_range_id: [''],
      red_range_id: [''],
      project_mapping: Number(this.projectId),
      id: [],
      trend_analysis_id: Number(data?.trend_analysis_id),
      unit_id: Number(data?.unit_id),
      metric_id: Number(data.metric_id),
      is_important: false,
      balance_score_card_id: Number(this.metric_category_id),
    })
  }

  closePanel(e: any) {
    this.rightpanel = "hide";
  }
  matrixValueFormArray() {
    return this.metricsForm.get('items') as FormArray;
  }

  addMetric(data: any) {
    this.noRecords = "hide";
    this.filteredData = this.filteredData.filter((i: any) => {
      if (i.id != data.id) {
        return i;
      }
    });
    this.items = this.metricsForm.get('items') as FormArray;
    this.selectedMetricList.push(data);
    this.items.push(this.BuildForm(data));
  }

  onFormSubmit() {
    this.spinner.show();
    this.formarrayvalues = this.metricsForm.value.items;
    this.selectedMetricList.forEach((centerPaneData: any) => {
      let obj = this.formarrayvalues.find((filterData: any) => filterData.name === centerPaneData.name)
      obj['is_important'] = centerPaneData['is_important']
      this.formarrayvalues.push(obj);
    })
    if(this.metric_category_id){
      this.projectService.updateMetrics(this.formarrayvalues, this.projectId, this.metric_category_id).subscribe((response: any) => {
        if (response == 'Metric Saved Successfully') {
          this.toastr.success(response);
          this.router.navigate([`view/`+btoa(this.projectId+"")+`/scorecard/`]);
          this.spinner.hide();
        }
        this.spinner.hide();
      })
    }else{
      // this.projectService.addmetrics(this.formarrayvalues, this.projectId, this.metric_category_id).subscribe((response: any) => {
      //   if (response == 'Metric Saved Successfully') {
      //     this.toastr.success(response);
      //     this.router.navigate([`view/`+btoa(this.projectId+"")+`/scorecard/`]);
      //     this.spinner.hide();
      //   }
      //   this.spinner.hide();
      // })
    }

  }

  onChange() {
    this.filteredData = this.metricsdata.filter((datum: any) => datum.name.indexOf(this.searchText) > -1);
  }

  removeMetrics(item: any) {
    var BreakException = {};
    try {
      let parentIndex = -1;
      this.selectedMetricList = this.selectedMetricList.filter((e: any) => e.id != item.id);
      let itemIndex = this.items.value.findIndex((index: any) => index.name == item.name)
      this.items.value.splice(itemIndex, 1);
      this.filteredData.push(item);
    }
    catch (e) {
      if (e !== BreakException) throw e;
    }
  }

  getprojectId() {
    this.activatedRoute.params.subscribe(params => {
      this.projectId = Number(atob(params.id));
      this.metric_category_id = Number(atob(params.metricId));
      console.log(params);
      
      this.merticname = params.metricname;
    });
  }

  getSelectedMetricData() {
    this.spinner.show();
    this.projectService.getselectedMetric(this.projectId, this.metric_category_id).subscribe((response: any) => {
      this.noRecords = "hide";
      this.selectedmetrics = response;
      if (response) {
        for (var val of response) {
          this.items = this.metricsForm.get('items') as FormArray;
          this.selectedMetricList.push(val);
          this.items.push(this.BuildForm(val));
          this.noRecords = "hide";
          this.norecordFound = "hide";
          /*  if (this.items?.length > 0) {
             this.noRecords == 'show'
           } */
          this.spinner.hide();
        }
        this.metricsList();
        this.spinner.hide();
      }
    },(error)=> this.spinner.hide())
    this.metricsList();
  }

  getUnit() {
    this.projectService.getUnitData().subscribe((response: any) => {
      this.unitlist = response.records
    })
  }

  frequencyList() {
    this.projectService.getfrequencyList().subscribe((response: any) => {
      this.frequencyData = response.records;
    })
  }

  metricsList() {
    this.spinner.show();
    this.projectService.getMetrics(this.metric_category_id).subscribe((response: any) => {

      if (response) {
        this.metricsdata = response;
        this.filteredData = response;
        this.noRecords = "show";
        this.selectedMetricList.forEach((centrePaneData: any) => {
          this.filteredData = this.filteredData.filter((rightPaneData: any) => rightPaneData.name !== centrePaneData.name);
          this.spinner.hide();
        });
        this.spinner.hide();
      }
      else {
        this.spinner.show();
      }

    })
  }

  scorecard() {
    this.router.navigate([`view/`+btoa(this.projectId+"")+`/scorecard/`])
  }

  markImportant() {
  }

}
